

























































































import {
  groupsStore,
  integrationsStore,
  uiStore,
} from '@/store'
import { preventHtmlTags } from '@/utils'
import {
  Integration,
  IntegrationForm,
  IntegrationKind,
} from '@tada-team/tdproto-ts'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'IntegrationManagement',
  components: {
    SectionComment: () => import('./SectionComment.vue'),
    SectionControlButtons: () => import('./SectionControlButtons.vue'),
    SectionEnabled: () => import('./SectionEnabled.vue'),
    SectionFormField: () => import('./SectionFormField.vue'),
    SectionGroup: () => import('./SectionGroup.vue'),
  },
})
export default class IntegrationManagement extends Vue {
  fieldsErrors: Partial<Record<keyof Integration, string>> = {}
  formFieldsErrors: Record<string, string> = {}
  generalError = ''
  integration: Integration | null = null
  isLoading = false
  isNew = false
  kind: IntegrationKind | null = null

  // TODO: remove any, set type for payload
  get currentModalPayload (): any {
    return uiStore.getters.currentModalPayload
  }

  get integrationsKindsByKind (): Record<string, IntegrationKind> {
    return integrationsStore.getters.integrationsKindByKind
  }

  // fields of integration to display and (possibly) edit
  get availableFields (): string[] {
    // TODO!: this thing is a bit ugly, since it includes public mappableFields
    // that do not belong to Integration class but to any tdproto class.
    // It is harmless for now as template checks against specific fields anyway.
    return Object.keys(this.integration ?? {})
  }

  // list of field objects to display and (possibly) edit
  get availableFormFields (): Partial<IntegrationForm> {
    if (!this.integration?.form) return {}

    // filter since empty form fields are still there with undef default values
    return Object.fromEntries( // reconstruct the object from
      Object.entries(this.integration.form) // current integration form fields
        .filter(([k, f]) => (
          !!f && // excluding those, whose values are undef
          k !== 'mappableFields' // TODO!: this is an ugly tdproto bodge. tdproto exposes mappableFields on each object instance.
        )),
    )
  }

  get canSave (): boolean {
    return (
      !!this.integration?.group && // group is set
      !this.generalError && // no general errors after last request
      Object.values(this.fieldsErrors).every(e => !e) && // no errors in fields
      Object.values(this.formFieldsErrors).every(e => !e) // no errors in form fields
    )
  }

  get headerCaption (): string {
    const caption = this.isNew
      ? this.$t('modals.IntegrationManagement.titleAdd')
      : this.$t('modals.IntegrationManagement.titleEdit')
    return `${caption} — ${this.kind?.title}`
  }

  created () {
    const { kind, uid, integration } = this.currentModalPayload
    this.isNew = uid === null

    if (integration) {
      // if an entire object is passed in - just get data from it
      // (used to preserve unsaved changes when leaving to another modal)
      this.integration = integration
      this.kind = this.integrationsKindsByKind[integration.kind] ?? null
    } else if (this.isNew) {
      // if creating a new one - reconstruct template from integration kind
      this.kind = this.integrationsKindsByKind[kind] ?? null
      this.integration = this.kind
        ? Integration.fromJSON(this.kind.template.toJSON())
        : null
    } else {
      // if editing - deep copy integration object and get 'kind' out of it
      const foundIntegration = integrationsStore.getters.integrationsByUid[uid]
      this.integration = foundIntegration
        ? Integration.fromJSON(foundIntegration.toJSON())
        : null
      if (!this.integration?.kind) return
      this.kind = this.integrationsKindsByKind[this.integration.kind] ?? null
    }

    // fill fieldsErrors with available keys and default error values
    this.fieldsErrors = Object.fromEntries(
      this.availableFields.map(f => [f, '']),
    )

    // fill formFieldsErrors with available keys and default error values
    this.formFieldsErrors = Object.fromEntries(
      Object.keys(this.availableFormFields).map(k => [k, '']),
    )
  }

  groupDisplayName (jid: string | null): string {
    if (!jid) return ''
    return groupsStore.getters.displayName(jid)
  }

  clearErrors (type: keyof Integration): void {
    this.fieldsErrors[type] = ''
    this.generalError = ''
  }

  /**
   * This is not actually a close method. It should rather be called
   * 'backToIntegrations'. Closes current modal and opens 'Integrations'.
   * Why this naming? Becase modal wrapper uses close() of this component
   * to handle clicking on (X) button.
   */
  close (): void {
    uiStore.actions.showModal({
      instance: 'Integrations',
      payload: { tab: 'connected' },
    })
  }

  /**
   * This is the actual close method, that closes this modal.
   * Used to handle clicking 'open chat' in child component.
   */
  closeCompletely (): void {
    uiStore.actions.hideModal()
  }

  async commit (): Promise<void> {
    if (!this.integration) return // should never execute this, but just in case
    this.isLoading = true

    try {
      const payload = Integration.fromJSON(this.integration.toJSON())
      const integration = this.isNew
        ? await integrationsStore.actions.create(payload)
        : await integrationsStore.actions.edit({ integration: payload })
      this.integration = Integration.fromJSON(integration.toJSON())
      this.kind = this.integrationsKindsByKind[this.integration.kind] ?? null

      /* If it was a new one and it was successfully created, then
      do not close the modal window and allow user to edit it in place and
      maybe copy 'key' to use elsewhere. It is not a new one from this point on.
      Else - just close the modal window. User finished editing it. */
      this.isNew
        ? this.isNew = false // then it is not new anymo
        : this.close()
    } catch (e) {
      if (e.details) {
        e.details?.[''] && (this.generalError = e.details[''])
        const { group, comment, enabled } = e.details
        group && (this.fieldsErrors.group = group)
        comment && (this.fieldsErrors.comment = comment)
        enabled && (this.fieldsErrors.enabled = enabled)
      }
    }
    this.isLoading = false
  }

  async remove (): Promise<void> {
    const uid = this.integration?.uid
    if (!uid) return

    const yes = async () => await integrationsStore.actions.remove(uid)

    const no = () => uiStore.actions.showModal({
      instance: this.$options.name || 'IntegrationManagement',
      payload: {
        uid: this.integration?.uid ?? null,
        kind: this.kind?.kind ?? null,
      },
    })

    const text = this.$t(
      'modals.IntegrationManagement.deleteText',
      {
        name: preventHtmlTags(this.kind?.title ?? ''),
        group: preventHtmlTags(
          this.groupDisplayName(this.integration!.group ?? ''),
        ),
      },
    )

    uiStore.actions.showModal({
      instance: 'universal-yes-no',
      payload: {
        title: this.$t('modals.IntegrationManagement.deleteTitle'),
        text,
        yesText: this.$t('common.delete'),
        yes,
        no,
        nextModal: { instance: 'Integrations' },
      },
    })
  }

  showHelp (): void {
    const { name: previousInstance } = this.$options
    // passing an entire integration object to preserve unsaved changes
    const previousPayload = { integration: this.integration }
    const data = {
      kind: this.kind?.title,
      help: this.integration?.help,
    }
    uiStore.actions.showModal({
      instance: 'IntegrationHelp',
      payload: { previousInstance, previousPayload, data },
    })
  }
}
